import React, { useState } from "react"
import Layout from "components/layout"
import SGTextfield from "../../components/textfield"
import * as Clickable from "components/clickable"
import * as styles from "./style.module.scss"
import { Typography } from "components/typography"
import { apis as API } from "@sog/sdk"
import { navigate } from "gatsby"
import useMediaQuery from "@mui/material/useMediaQuery"

const HomeLoansRegister = () => {
  const isWide = useMediaQuery("(min-width:768px)")

  const [isRegistered, setIsRegistered] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const validateInputs = () => {
    if (firstName.length === 0 || lastName.length === 0) {
      return false
    }
    if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      return false
    }
    return true
  }

  return (
    <Layout title="Home Loans Registration">
      <div className="container-center">
        <div className="home-container">
          {isRegistered ? (
            <div>
              <Typography.H1 style={{ marginTop: "60px" }}>Thank you</Typography.H1>

              <div style={{ marginTop: isWide ? "80px" : "40px" }}>
                <Typography.P comp="large">You’ll shortly receive an email confirming your registration.</Typography.P>
              </div>

              {/* <Typography.P>If you have any issues, please contact Stay or Go by reply email.</Typography.P> */}

              <Clickable.Text
                style={{ width: isWide ? "33%" : "100%", marginTop: isWide ? "100px" : "50px", marginLeft: "auto", marginRight: "auto" }}
                comp={3}
                className="survey-button"
                onClick={() => {
                  navigate("/")
                }}
              >
                Home
              </Clickable.Text>
            </div>
          ) : (
            <div style={{ opacity: isLoading ? 0.4 : 1 }}>
              <Typography.H1 style={{ marginTop: "60px" }}>Registration</Typography.H1>

              <div style={{ marginTop: isWide ? "80px" : "40px" }}>
                <Typography.P comp="large">Register to be one of the first to use Stay or Go to get a better deal on your home loan without needing to switch banks.</Typography.P>
              </div>

              <div style={{ width: isWide ? "40%" : "100%", marginTop: isWide ? "100px" : "50px", marginLeft: "auto", marginRight: "auto" }}>
                <SGTextfield className={styles.tf} label="First Name" onChange={e => setFirstName(e.target.value)} />
                <SGTextfield className={styles.tf} label="Last Name" onChange={e => setLastName(e.target.value)} />
                <SGTextfield className={styles.tf} label="Email Address" onChange={e => setEmail(e.target.value)} />

                <Clickable.Text
                  comp={3}
                  style={{
                    height: "56px",
                    width: "100%",
                    marginTop: "30px",
                  }}
                  disabled={isLoading || !validateInputs()}
                  onClick={() => {
                    setIsLoading(true)
                    const data = {
                      first_name: firstName,
                      last_name: lastName,
                      email: email,
                    }
                    API.submitHLRegistration(data, responseJson => {
                      setIsRegistered(true)
                    })
                  }}
                >
                  Submit
                </Clickable.Text>

                <Typography.P style={{ marginTop: 30 }}>
                  {"By submitting, you agree to the Stay or Go "}
                  <a href="/privacy/" target="_blank" style={{ color: "#4f8ccc", textDecoration: "inherit" }}>
                    Privacy Policy
                  </a>
                  {" and "}
                  <a href="/terms/" target="_blank" style={{ color: "#4f8ccc", textDecoration: "inherit" }}>
                    Terms of Use
                  </a>
                  {". This may include sharing the information you provide with your financial services provider and others to facilitate negotiation. We may contact you about products and offers made."}
                </Typography.P>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default HomeLoansRegister
